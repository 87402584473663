.content-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 100%;
  min-height: 640px;

  /* responsive */

  padding: 48px;
}

.content__title {
  margin-top: 0;
}

.content__body {
  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

#new-post-body {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

div.selected-mood {
  font-size: 400%;
  padding-top: 10px;
  padding-bottom: 45px;
}

#page-description {
  display: flex;
  flex-direction: column;
}

#page-description span {
  margin-bottom: 1.6rem;
}

#page-description span:last-child {
  margin-bottom: 0;
}

#emoji-picker {
  padding-bottom: 20px;
}

#post-form {
  padding-top: 20px;
}

.post-feed {
  max-width: 800px;
  margin: 0 auto;
  background-color: #f0f0f0;
  padding: 20px;
}

.feed-post {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.feed-post-date {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.feed-post-content {
  font-size: 50px;
  text-align: center;
}

nav li { display: inline-block; }


@media only screen and (max-width: 480px) {
  .content-layout {
    padding: 1.6rem;
  }

  .content__title {
    font-size: 2.4rem;
  }
}
